import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  ref: "serviceSelector",
  class: "service-selector"
}
const _hoisted_2 = { class: "service-selector-initial" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchList = _resolveComponent("SearchList")!
  const _component_PopularServices = _resolveComponent("PopularServices")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchList, {
        placeholder: _ctx.placeholder,
        "container-height": _ctx.height,
        "search-data": _ctx.services,
        "show-initial-data": false,
        "hover-results": true,
        class: "mb-4",
        onItemSelected: _ctx.selectService,
        onIsActive: _ctx.handleActiveSearch
      }, null, 8, ["placeholder", "container-height", "search-data", "onItemSelected", "onIsActive"]),
      (!_ctx.searchActive)
        ? (_openBlock(), _createBlock(_component_PopularServices, {
            key: 0,
            "popular-services": _ctx.popularServices,
            onStartInterview: _ctx.startInterview
          }, null, 8, ["popular-services", "onStartInterview"]))
        : _createCommentVNode("", true),
      (_ctx.cartServices.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-primary next",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToNotesStep()))
          }, [
            _createTextVNode(" Next "),
            _createVNode(_component_icon, { icon: ['fal', 'arrow-right'] })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512))
}